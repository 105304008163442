.coming-img {
  height: auto;
  width: 28rem;
  margin: 0 auto;
  display: block;
}

.recontainer {
  padding: 0px 10rem;
  margin-top: 20vh;
}

.h-top {
  font-size: 2.3rem;
  font-weight: 600;
  color: #000000cc;
}

.p-coming {
  font-size: 1.2rem;
}

.m-15 {
  margin-bottom: 1.5rem !important;
}

.m-05 {
  margin-bottom: 0.5rem !important;
}

@media (max-width: 768px) {
  .recontainer {
    padding: 0px 2rem;
    margin-top: 20vh;
  }

  .h-top {
    font-size: 2rem;
  }

  .coming-img {
    width: 100%;
  }
}
